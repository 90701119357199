import './App.css';

function App() {
  return (
    <body className="bg-gradient-to-br from-gray-900 to-black">
      <div
        className="text-gray-300 container mx-auto p-8 overflow-hidden md:rounded-lg md:p-10 lg:p-12"
      >
        <div className="flex justify-between">
          <h1 className="font-sans text-3xl font-medium tracking-wide">Crucible Digital</h1>
            info@crucibledigital.com
        </div>

        <div className="h-32 md:h-40"></div>

        <p
          className="font-sans text-4xl font-bold text-gray-200 max-w-5xl lg:text-7xl lg:pr-24 md:text-6xl"
        >
        Solutions for forward-thinking businesses.
        </p>
        <div className="h-10"></div>
        <p className="max-w-2xl font-serif text-xl text-gray-400 md:text-2xl">
          Delivering practical software solutions that add business value to processes, teams, and companies. <br/>
        </p>

        <div className="h-64 md:h-64"></div>
      

      </div>
    </body>
  );
}

export default App;
